import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';
import { getI18n } from 'react-i18next';
import { CellRenderers, ColumnFilters } from '@/components/entity';

import LoggedHourBaseOrderFormSchema from '@/_base/loggedHour/LoggedHourBaseOrderFormSchema';
import LoggedHourBaseModel from '@/_base/loggedHour/LoggedHourBaseModel';
import LoggedHourBaseSimpleSchema from '@/_base/loggedHour/LoggedHourBaseSimpleSchema';
import { LoggedHourBaseTableSchemaWithoutActions, actions } from '@/_base/loggedHour/LoggedHourBaseTableSchema';
import LoggedHourBaseInsertForm from '@/_base/loggedHour/components/LoggedHourBaseInsertForm';
import LoggedHourBaseUpdateForm from '@/_base/loggedHour/components/LoggedHourBaseUpdateForm';
import LoggedHourBaseView from '@/_base/loggedHour/components/LoggedHourBaseView';
import LoggedHoursTable from './LoggedHourTable';

const { t } = getI18n();

const activityIndex = LoggedHourBaseTableSchemaWithoutActions.findIndex((item) => item.field === 'category');

LoggedHourBaseSimpleSchema.extend({
  tenant: {
    optional: false,
  },
});

LoggedHourBaseTableSchemaWithoutActions.splice(activityIndex, 0, {
  field: 'parentCategory',
  label: t(['hoursOverview:parentCategory'], 'parentCategory') || undefined,
  cell: CellRenderers.AutoRenderer,
  filter: ColumnFilters.StringFilter,
  sortable: false,
});

const LoggedHourEntity: Entity<typeof LoggedHourBaseModel> = {
  name: 'logged_hour',

  simpleSchema: LoggedHourBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(LoggedHourBaseSimpleSchema),
    orderSchema: [...LoggedHourBaseOrderFormSchema],
    component: LoggedHourBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(LoggedHourBaseSimpleSchema),
    orderSchema: [...LoggedHourBaseOrderFormSchema],
    component: LoggedHourBaseUpdateForm,
  },

  table: {
    schema: [...LoggedHourBaseTableSchemaWithoutActions, actions],
    component: LoggedHoursTable,
  },

  view: {
    component: LoggedHourBaseView,
  },

  model: LoggedHourBaseModel,

  routes: {},

  settings: {
    displayField: 'id',
  },
};

export default LoggedHourEntity;
