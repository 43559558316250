import axios from 'axios';

import config from '@/config';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = config.app.backendUrl;

axios.interceptors.request.use(async (options) => {
  if (options.method !== 'get') {
    options.headers['x-csrf-protection'] = '1';
  }

  return options;
});

export default axios;
