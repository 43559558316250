import env from './env';

export default {
  facebookLoginEnabled: env('AUTH_FACEBOOK_LOGIN_ENABLED', false),
  googleLoginEnabled: env('AUTH_GOOGLE_LOGIN_ENABLED', false),
  impersonateEnabled: env('AUTH_IMPERSONATE_ENABLED', true),
  passwordStrength: env('AUTH_PASSWORD_STRENGTH', 4),
  registerEnabled: env('AUTH_REGISTER_ENABLED', true),
  termsAndConditionsEnabled: env('AUTH_TERMS_AND_CONDITIONS_ENABLED', true),
};
