import { cloneDeep } from 'lodash';

import type EntityType from '@boilerplate/types/entity';
import type { StringKeys } from '@boilerplate/types/helpers';

export type Doc = Record<string, unknown>;

export default function getGraphqlData<T extends Doc = Doc, R = Partial<T> & Record<`${StringKeys<T>}Id`, T[StringKeys<T>]>>(
  Entity: EntityType,
  variables: T,
  type: 'insert' | 'update'
): R {
  const data = cloneDeep(variables) as unknown as R; // Allow '${key}Id' values

  Object.entries(data).forEach(([key, value]) => {
    const field = Entity[type].simpleSchema.getDefinition(key);
    if (!field) {
      return;
    }

    if (field?.optional && field?.form && field.type[0].type === Number && typeof value === 'undefined') {
      data[key] = null;
    }

    if (field.relation && field.type[0].type === String) {
      data[`${key}Id`] = typeof value === 'undefined' ? null : value;

      delete data[key];
    }
  });

  return data;
}
