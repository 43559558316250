import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import UserTenantRoleBaseOrderFormSchema from '@/_base/userTenantRole/UserTenantRoleBaseOrderFormSchema';
import UserTenantRoleBaseModel from '@/_base/userTenantRole/UserTenantRoleBaseModel';
import UserTenantRoleBaseSimpleSchema from '@/_base/userTenantRole/UserTenantRoleBaseSimpleSchema';
import UserTenantRoleBaseTableSchema from '@/_base/userTenantRole/UserTenantRoleBaseTableSchema';
import UserTenantRoleBaseInsertForm from '@/_base/userTenantRole/components/UserTenantRoleBaseInsertForm';
import UserTenantRoleBaseUpdateForm from '@/_base/userTenantRole/components/UserTenantRoleBaseUpdateForm';
import UserTenantRoleBaseTable from '@/_base/userTenantRole/components/UserTenantRoleBaseTable';
import UserTenantRoleBaseView from '@/_base/userTenantRole/components/UserTenantRoleBaseView';

const UserTenantRoleEntity: Entity<typeof UserTenantRoleBaseModel> = {
  name: 'user_tenant_role',

  simpleSchema: UserTenantRoleBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(UserTenantRoleBaseSimpleSchema),
    orderSchema: [...UserTenantRoleBaseOrderFormSchema],
    component: UserTenantRoleBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(UserTenantRoleBaseSimpleSchema),
    orderSchema: [...UserTenantRoleBaseOrderFormSchema],
    component: UserTenantRoleBaseUpdateForm,
  },

  table: {
    schema: UserTenantRoleBaseTableSchema,
    component: UserTenantRoleBaseTable,
  },

  view: {
    component: UserTenantRoleBaseView,
  },

  model: UserTenantRoleBaseModel,

  routes: {},

  settings: {
    displayField: 'id',
  },
};

export default UserTenantRoleEntity;
